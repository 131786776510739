<template>
  <div class="shop-box">
    <div v-if="list&&list.length!=0" class="shopcar">
      <div v-for="(items,index) in list" :key="index" class="shopcar-list">
        <div class="shopcar-list-item-title">
          <span
            :class="items.selected==0?'ele-left':'ele-leftbg'"
            @click.stop="changeChosenStatus(items.cartId,items.selected,items.channelId,'channel',items)"
          />
          <img :src="items.imgIcon">
          <span>{{ items.name }}</span>
        </div>
        <!-- {{items.activityData.productList}} -->
        <div
          v-for="(ele,idx) in items.activityData"
          :key="idx"
          :class="ele.isSpecialEvent==2|| ele.isSpecialEvent==3?'hdbox':''"
        >
          <!--判断京东是否免运费-->
          <div v-if="ele.isSpecialEvent==0 && items.channelId!=0 && items.channelId!=12 &&idx<1" class="yf-title">
            <div v-if="items.allMoney-freightRule>0" class="divone">已免运费</div>
            <div v-else class="divtwo"><span>还差{{ Math.round((freightRule - items.allMoney) * 100) / 100 }}免运费</span>
              <!-- <span @click='gocdb(items)'>去凑单</span> -->
            </div>
          </div>
          <div v-if="ele.isSpecialEvent==1" class="hd">
            <span class="hd-title">9.9元3件活动</span>
            <div>
              <span>{{ ele.type >= 3 ? tostrings(3, ele.enjoyedDiscountTips, ele.type) : tostrings(3, ele.moreDiscountTips, ele.type) }}</span>
            </div>
            <span v-if="ele.type<3" class="qcd" @click="gocd(ele)">去凑单</span>
          </div>
          <div v-if="ele.isSpecialEvent==2" class="hd"><span class="hd-title">满减优惠</span>
            <div>
              <!-- <span class="hd-title">{{ele.enjoyedDiscountTips}}</span> -->
              <span>{{ ele.activityRules[0].Num >= ele.activityRules[0].fullPrice ? tostrings(5, ele.enjoyedDiscountTips, ele.activityRules[0].fullPrice, ele.activityRules[0].reducePrice) : tostrings(6, ele.moreDiscountTips, ele.activityRules, ele.activityRules[0].reducePrice, ele.activityRules) }}</span>
            </div>
            <span
              v-if="ele.activityRules[0].Num<ele.activityRules[0].fullPrice"
              class="qcd"
              @click="gocd(ele)"
            >去凑单</span>
          </div>
          <div v-if="ele.isSpecialEvent==3" class="hd">
            <span class="hd-title">打折优惠</span>
            <div>
              <!-- <span class="hd-title">{{ele.enjoyedDiscountTips}}</span> -->
              <span>{{ ele.activityRules[0].Num >= ele.activityRules[0].fullPrice ? tostrings(1, ele.enjoyedDiscountTips, ele.activityRules[0].fullPrice, ele.activityRules[0].reducePrice) : tostrings(2, ele.moreDiscountTips, ele.activityRules, ele.activityRules[0].reducePrice, ele.activityRules) }}</span>
            </div>
            <span
              v-if="ele.activityRules[0].Num<ele.activityRules[0].fullPrice"
              class="qcd"
              @click="gocd(ele)"
            >去凑单</span>
            <!-- 活动优惠{{ele.enjoyedDiscountTips}}{{ele.moreDiscountTips}}</span>满<span>{{ele.activityRules[0].fullPrice}}</span>件，打<span>{{ele.activityRules[0].reducePrice*10}}折</span> -->
          </div>
          <div
            v-for="item in ele.productList"
            :key="item.cartId"
            ref="ele"
            class="shopcar-list-item-content"
            @click.stop="goodsDetail(item)"
          >
            <span
              :class="item.selected==0?'ele-left':'ele-leftbg'"
              @click.stop="changeChosenStatus(item.cartId,item.selected,item.channelId,'single',item,items)"
            />
            <img :src="item.goodCover" alt="">
            <div class="describe">
              <span class="header">{{ item.goodName }}</span>
              <span class="header-two">{{ item.goodSpec }}</span>
              <div class="header-three" style="text-decoration: line-through">{{ item.nowPrice }}</div>
              <div class="footer">
                <div class="footer-left">￥{{ item.vipPric }}</div>
                <div class="footer-right">
                  <span class="jian" @click.stop="jian(item,ele)">-</span>
                  <span>{{ item.goodNum }}</span>
                  <span class="jia" @click.stop="add(item,ele)">+</span>
                </div>
              </div>
            </div>
            <div class="delate" @click.stop="deleteShop(item)">删除</div>
          </div>
        </div>
      </div>
      <div v-if="loseList&&loseList.length!=0" class="noshop">
        <div class="noshop-title">
          <span class="noshop-title-left">失效宝贝{{ loseList.length }}件</span><span
            class="noshop-title-right"
            @click.stop="deletelose"
          >清除失效商品</span>
        </div>
        <div v-for="(item,index) in loseList" :key="index" class="noshop-items">
          <div class="sx">失效</div>
          <img :src="item.goodCover" alt="">
          <div class="lose-title">
            <div class="div">{{ item.goodName }}</div>
            <div class="lt-div">该商品已下架</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box1" />
    <div class="money">
      <div class="footer-left">
        <span
          :class="allSelected==0?'ele-left':'ele-leftbg'"
          @click.stop="changeChosenStatus(0,allSelected,0,'all')"
        />
        <span>全选</span>
      </div>
      <div class="footer-center">
        <span><span class="span1">合计:</span><span class="span2">￥</span><span class="span3">{{ money }}</span></span>
        <span class="span4">活动已优惠：{{ yhmoney }}</span>
      </div>
      <div :class="totalCount > 0 ?'footer-hj':'footer-hjs'" @click.stop="gopay">
        <span>{{ totalCount > 0 ? '结算(' + totalCount + ')' : '结算' }}</span>
      </div>
    </div>
    <Tabbar />
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from 'vue'
import {
  getShopCarList,
  getLoseGoods,
  handleGoodsCount,
  changeChoseStatus,
  deleteGoods
} from '@/services/goods.js'
import { Button, Checkbox, Dialog, Skeleton, SubmitBar, SwipeCell, Toast } from 'vant'

Vue.use(SwipeCell).use(Button).use(SubmitBar).use(Checkbox).use(Toast).use(Skeleton).use(Dialog)
import Tabbar from '@/component/Tabbar'
export default {
// import引入的组件需要注入到对象中才能使用
  components: {
    Tabbar
  },
  data() {
    // 这里存放数据
    return {
      left: false, // 选中状态
      list: [], // 商品列表
      loseList: [], // 失效商品列表
      money: 0, // 合计价格
      yhmoney: 0, // 优惠价格
      num: 0, // 商品单个数量
      nums: 0, // 总购买数量
      totalCount: 0, // 选中商品数量
      selected: 0, // 判断全选或者反选
      allSelected: 0, // 全选
      selectedshop: [], // 选中的商品
      top: 0,
      freightRule: 0, // 京东运费
      jdmoney: 0,
      activityId: 0// 活动id
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getList()
    this.getLoseGoods()
    this.orderlist()
  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  },
  // 方法集合
  methods: {
    shopnum(e) {
      this.money = 0
      const selected = []
      let money = 0
      let yhmoney = 0
      const selectedshop = []
      const jdmoney = 0
      let hcmoney = 0
      // 循环算数选中商品的总数量和金钱
      this.list.forEach(ele => {
        //  money+=ele.allMoney
        if (ele.channelId > 0 && ele.channelId < 12) {
          ele.allMoney = 0
          ele.activityData.forEach(jditems => {
            jditems.productList.forEach(jditem => {
              if (jditem.selected == 1) {
                ele.allMoney += jditem.goodNum * jditem.vipPric
              }
            })
          })
        } else {
          // 会场打折
          ele.activityData.forEach(jditems => {
            // 白拿商品
            if (jditems.isSpecialEvent === 4) {
              console.log('白拿价格', jditems)
            }
            if (jditems.isSpecialEvent === 3) {
              const dz = []
              var dzmoney = []
              jditems.productList.forEach(jditem => {
                // 判断选中的进行优惠
                // eslint-disable-next-line eqeqeq
                if (jditem.selected == 1) {
                  for (var o = 0; o < jditem.goodNum; o++) {
                    dz.push(jditem.vipPric)
                  }
                  dzmoney = dz.sort((a, b) => {
                    return b - a
                  })
                }
              })
              if (jditems.activityRules.length === 1) {
                jditems.activityRules[0].Num = dzmoney.length
                if (dzmoney.length >= jditems.activityRules[0].fullPrice) {
                  for (var i = 0; i < jditems.activityRules[0].fullPrice; i++) {
                    if (dzmoney[i]) {
                      yhmoney += dzmoney[i] * (1 - jditems.activityRules[0].reducePrice)
                    }
                  }
                }
              } else {
                // 如果dzmoney.length选中的数量大于jditems.activityRules的第一项那么直接去第一项进行优惠
                if (dzmoney.length > jditems.activityRules[0].fullPrice) {
                  jditems.activityRules[0].Num = dzmoney.length
                  for (let i = 0; i < jditems.activityRules[0].fullPrice; i++) {
                    if (dzmoney[i]) {
                      yhmoney += dzmoney[i] * (1 - jditems.activityRules[0].reducePrice)
                    }
                  }
                } else {
                  for (let i = 0; i < jditems.activityRules.length; i++) {
                    jditems.activityRules[0].Num = dzmoney.length
                    if (jditems.activityRules[i].fullPrice === dzmoney.length) {
                      for (var j = 0; j < jditems.activityRules[i].fullPrice; j++) {
                        if (dzmoney[j]) {
                          yhmoney += dzmoney[j] * (1 - jditems.activityRules[i].reducePrice)
                        }
                      }
                    }
                  }
                }
              }
            }
            // 满减活动
            if (jditems.isSpecialEvent == 2) {
              jditems.productList.forEach(jditem => {
                if (jditem.selected == 1) {
                  hcmoney += jditem.vipPric * jditem.goodNum
                }
              })
              jditems.activityRules[0].Num = hcmoney
              if (hcmoney > jditems.activityRules[0].fullPrice) {
                yhmoney += jditems.activityRules[0].reducePrice
              }
              // 需要先判断规则的第一项是否小于选中商品的价格然后进行加减
              // 否则需要循环匹配对应的规则直到小于
            }
            // 三单9.9
            if (jditems.isSpecialEvent == 1) {
              const activity = []
              jditems.productList.forEach(jditem => {
                // 判断选中的9.9数量
                if (jditem.selected == 1) {
                  for (var o = 0; o < jditem.goodNum; o++) {
                    activity.push(jditem)
                  }
                }
              })
              if (activity.length === 3) {
                yhmoney += 9.9 * 2
              }
              jditems.type = activity.length
            }
          })
        }
        this.jdmoney = jdmoney
        ele.activityData.forEach(itemdata => {
          itemdata.productList.forEach(itemlist => {
            if (itemlist.selected == 1) {
              selectedshop.push(itemlist)
              money += itemlist.goodNum * itemlist.vipPric
              this.totalCount += itemlist.goodNum
              selected.push(itemlist.selected)
            } else {
              selected.push(itemlist.selected)
            }
          })
        })
      })
      if (selected.includes(0)) {
        this.allSelected = 0
      } else {
        this.allSelected = 1
      }
      this.selectedshop = selectedshop
      this.totalCount = selectedshop.length
      this.yhmoney = Math.round(yhmoney * 100) / 100
      this.money = Math.round((money - yhmoney) * 100) / 100
    },
    // 删除商品
    deleteShop(item) { // 删除购物车商品
      const data = {
        uid: window.localStorage.getItem('uid') || '',
        cartId: item.cartId
      }
      deleteGoods(data).then(res => {
        if (Number(res.status) === 200) {
          Toast('删除成功')
          this.getList()
          this.orderlist()
        }
      })
    },
    handleStoreChose(item, idx, groupSelect) { // 改变单个店铺中的商品选中状态
      this.changeChosenStatus(item.cartId, item.select, item.channelId, groupSelect, item)
    },
    changeChosenStatus(cartId, types, channelId, groupSelect, item, items) { // 改变商品选中状态
      const that = this
      const type = types ? 0 : 1
      var data = {}
      if (groupSelect == 'all') {
        this.allSelected = type
        this.list.forEach(ele => {
          //  money+=ele.allMoney
          ele.selected = type
          ele.activityData.forEach(itemdata => {
            itemdata.productList.forEach(itemlist => {
              itemlist.selected = type
            })
          })
        })
        data = {
          groupSelect: groupSelect,
          uid: window.localStorage.getItem('uid') || '',
          type: type
        }
      }
      if (groupSelect == 'channel') {
        item.selected = type
        item.activityData.forEach(ele => {
          ele.productList.forEach(allstatus => {
            allstatus.selected = type
          })
        })
        data = {
          groupSelect: groupSelect,
          uid: window.localStorage.getItem('uid') || '',
          // cartId: cartId,
          type: type,
          channelId: channelId
        }
      }
      if (groupSelect == 'single') {
        item.selected = type
        var newItem = items.activityData.map((ele) => {
          return ele.selected
        })
        items.activityData.forEach(ele => {
          ele.productList.forEach(allitem => {
            newItem.push(allitem.selected)
          })
        })
        if (newItem.includes(0)) {
          items.selected = 0
        } else {
          items.selected = 1
        }
        data = {
          groupSelect: groupSelect,
          uid: window.localStorage.getItem('uid') || '',
          cartId: cartId,
          type: type
        }
      }
      changeChoseStatus(data)
      that.shopnum()
    },
    // 商品点击
    goodsDetail(obj) {
      if (obj.activityId) {
        this.$router.push('/goodsDetailZY?goodsId=' + obj.goodId + '&skuid=' + (obj.product_sku_id || obj.skuId) + '&activityId=' + obj.activityId)
      } else {
        this.$router.push('/goodsDetailZY?goodsId=' + obj.goodId + '&skuid=' + (obj.product_sku_id || obj.skuId))
      }
    },
    // 去凑单
    gocd(ele) {
      this.$router.push({
        path: '/Activitylist',
        query: {
          oid: ele.redirectParams.id
        }
      })
    },
    gocdb(ele) {
    },
    // 购物车减少数量
    async jian(item) {
      if (item.selected == 1) {
        if (item.goodNum == 1) {
          item.goodNum = 1
          Toast('不能再减少了呦')
          return
        }
        const data = {
          cartId: item.cartId,
          type: 1, // 0：增加 1：减少
          uid: window.localStorage.getItem('uid') || '',
          channelId: 0
        }
        const res = await handleGoodsCount(data)
        if (Number(res.status) !== 400) {
          item.goodNum--
          this.shopnum()
        }
      }
      // if(res.status==200){
      //     this.getList()
      //   }
    },
    // 购物车增加数量
    async add(item) {
      if (item.selected == 1) {
        // this.totalCount++
        const data = {
          cartId: item.cartId,
          type: 0, // 0：增加 1：减少
          uid: window.localStorage.getItem('uid') || '',
          channelId: 0
        }
        const res = await handleGoodsCount(data)
        if (Number(res.status) !== 400) {
          item.goodNum++
          this.shopnum()
        }
      }

      // if(res.status==200){
      //     this.getList()
      //   }
    },
    // 获取购物车列表
    async getList() {
      const data = {
        uid: window.localStorage.getItem('uid') || ''
      }
      const res = await getShopCarList(data)
      this.list = res.data.groupProductList || []
      this.freightRule = res.data.freightRule
      this.shopnum()
    },
    orderlist() {
      var $this = this														// 将$this保存 区分以下触发事件的this
      setTimeout(() => {
        // const accountEl = document.getElementsByClassName('shopcar')[0]
        var container = $this.$refs.ele || []
        var x, X
        // var y, Y
        for (var i = 0; i < container.length; i++) { // 为每个特定DOM元素绑定touchstart touchmove时间监听 判断滑动方向
          container[i].addEventListener('touchstart', function(event) { // 记录初始触控点横坐标
            x = event.changedTouches[0].pageX
            // y = event.changedTouches[0].pageY
            document.getElementsByClassName('shopcar')[0].style.overflowY = 'auto'
          }, false)
          container[i].addEventListener('touchmove', function(event) {
            // document.body.parentNode.style.overflowY = "hidden";
            X = event.changedTouches[0].pageX
            // Y = event.changedTouches[0].pageY
            // var DY = Y - y
            var DX = X - x // 记录当前触控点横坐标
            if ($this.expansion) { // 判断是否展开，如果展开则收起
              // document.getElementsByClassName("shopcar")[0].style.overflowY = "scroll";
              // $this.expansion.className = "shopcar-list-item-content";
            }
            if (DX > 10) {
              this.className = 'right shopcar-list-item-content'
            } else if (DX < -80) {
              this.className = 'left shopcar-list-item-content'
            } else {
              // 此处为空
            }
          }, false)
          container[i].addEventListener('touchend', function(event) {
            document.getElementsByClassName('shopcar')[0].style.overflowY = 'scroll'
            x = 0
            X = 0
            // Y = 0
            // y = 0
          }, false)
        }
      }, 1000)
    },
    // 获取失效列表
    async getLoseGoods() {
      const data = {
        uid: window.localStorage.getItem('uid') || ''
      }
      const res = await getLoseGoods(data)
      this.loseList = res.data || []
    },
    // 清空失效列表
    deletelose() {
      const that = this
      this.loseList.forEach(ele => {
        that.deleteShop(ele)
      })
      that.getLoseGoods()
    },
    // 去结账
    gopay() {
      const pay = []
      if (this.totalCount <= 0) {
        Toast('先选择你喜欢的宝贝吧!')
        return
      }
      this.selectedshop.forEach(item => {
        pay.push(item.cartId)
      })
      const mk = pay.join(',')

      this.$router.push({
        path: '/placeCaroder',
        query: {
          cartId: mk,
          yhmoney: this.yhmoney
        }
      })
    },
    tostrings(type, text, num, reducePrice, nownum) {
      if (type === 1) {
        var newtext = text.replace('{1}', num)
        var newtext1 = newtext.replace('{2}', reducePrice * 10)
        return newtext1
      }
      if (type === 2) {
        const addnum = nownum[0].fullPrice - num[0].Num
        var text1 = text.replace('{1}', addnum)
        var text2 = text1.replace('{2}', nownum[0].fullPrice)
        var text3 = text2.replace('{3}', reducePrice * 10)
        return text3
      }
      if (type === 3) {
        if (num >= 3) {
          const text1 = text.replace('{1}', 3)
          const text2 = text1.replace('{2}', 9.9)
          const text3 = text2.replace('{3}', 3)
          return text3
        } else {
          const text1 = text.replace('{1}', 9.9)
          const text2 = text1.replace('{2}', 3)
          const text3 = text2.replace('{3}', 3 - num)
          return text3
        }
      }
      if (type === 5) {
        const newtext = text.replace('{1}', num)
        const newtext1 = newtext.replace('{2}', reducePrice * 10)
        return newtext1
      }
      if (type === 6) {
        const addnum = nownum[0].fullPrice - num[0].Num
        const text1 = text.replace('{1}', addnum)
        const text2 = text1.replace('{2}', nownum[0].fullPrice)
        const text3 = text2.replace('{3}', reducePrice)
        return text3
      }
    }
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}

.shop-box {
  width: 100%;
  min-height: 100%;
  z-index: 0;
  padding-bottom: 110px;
}

//@import url(); 引入公共css类
.shopcar {
  width: 100%;
  height: 82vh;
  overflow: hidden;
  overflow-y: auto;
  background: #F8F8F8;

  .shopcar-list {
    width: 100%;
    background: #ffffff;
    margin-bottom: 10px;

    .hdbox {
      margin-top: 5px;
      border-bottom: 5px solid #F8F8F8;
    }

    .hd {
      width: 100%;
      height: 25px;
      display: flex;
      justify-content: flex-start;
      line-height: 25px;
      position: relative;

      .qcd {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }

      .hd-title {
        display: block;
        height: 25px;
        line-height: 25px;
        padding: 0 15px;
        background: red;
        color: #ffffff;
        margin-left: 15px;
      }

      > span {
        color: red;
      }
    }

    .yf-title {
      text-align: left;
      height: 25px;

      div {
        width: 100%;
        height: 100%;
        color: #F7263C;
        background: #F8F8F8;
        line-height: 25px;
        padding-left: 15px;
      }

      .divtwo {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
      }
    }

    .shopcar-list-item-title {
      width: 100%;
      height: 8vh;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: bold;

      .ele-left {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid #888888;
        margin-left: 10px;
        margin-right: 10px;
      }

      .ele-leftbg {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: url("../../assets/images/choose.png") no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
        margin-left: 10px;
      }

      img {
        width: 35px;
        height: 35px;
      }
    }

    .left {
      transform: translateX(-45px);
    }

    .right {
      transform: translateX(0);
    }

    .shopcar-list-item-content {
      width: 100%;
      height: 21vh;
      display: block;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      transition: all .3s ease;
      // transform: translateX(0);
      .ele-left {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid #888888;
        margin-left: 10px;
        margin-right: 10px;
      }

      .ele-leftbg {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: url("../../assets/images/choose.png") no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
        margin-left: 10px;
      }

      img {
        width: 30%;
        height: 80%;
      }

      .describe {
        width: 55%;
        height: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-left: 10px;

        .header {
          display: inline-block;
          width: 300px;
          font-weight: 400;
          font-size: 16px;
          height: 26px;
          line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .header-two {
          display: inline-block;
          font-size: 12px;
          color: #999999;
          background: #f6f6f6;
          padding: 0 10px;
          display: flex;
          justify-items: start;
          height: 26px;
          line-height: 26px;
        }

        .header-three {
          color: #999999;
          padding-left: 6px;
        }

        div {
          font-size: 14px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          justify-content: flex-start;
          height: 26px;
          line-height: 26px;
        }

        .footer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 28px;
          color: red;

          .footer-left {
            width: 40%;
          }

          .footer-right {
            width: 60%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            span {
              display: inline-block;
              width: 50px;
              height: 28px;
              text-align: center;
              line-height: 28px;
              color: #666;
              background: #F9F9F9;
              font-size: 28px;
            }

            .jian {
              display: inline-block;
              width: 28px;
              height: 28px;
              text-align: center;
              line-height: 28px;
              color: #666;
              background: #F9F9F9;
              font-size: 28px;
            }

            .jia {
              display: inline-block;
              width: 28px;
              height: 28px;
              text-align: center;
              line-height: 28px;
              color: #666;
              background: #F9F9F9;
              font-size: 28px;
            }
          }
        }
      }

      .delate {
        position: absolute;
        right: -45px;
        top: 10%;
        width: 45px;
        height: 80%;
        background: red;
        color: #ffffff;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.box1 {
  width: 100%;
  height: 18vh;
  position: fixed;
  bottom: 0;
  // background: red;
}

.money {
  width: 100%;
  height: 8vh;
  background: #FFFFFF;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 8vh;
  left: 0;

  .footer-left {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: 600;

    .ele-left {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: 1px solid #888888;
      margin-left: 10px;
      margin-right: 10px;
    }

    .ele-leftbg {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: url("../../assets/images/choose.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .footer-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;

    > span {
      width: 60%;
      text-align: left;
      color: red;

      .span1 {
        color: #333333;
        font-size: 12px;
      }

      .span2 {
        color: red;
        font-size: 10px;
      }

      .span3 {
        color: red;
        font-size: 16px;
      }
    }

    .span4 {
      font-size: 10px;
    }
  }

  .footer-hj {
    width: 30%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: red;
    color: #ffffff;
    margin-right: 10px;
  }

  .footer-hjs {
    width: 30%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #999999;
    color: #ffffff;
    margin-right: 10px;
  }
}

.noshop {
  width: 100%;
  background: #ffffff;

  .noshop-title {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .noshop-title-left {
      width: 30%;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }

    .noshop-title-right {
      width: 60%;
      font-size: 12px;
      font-weight: 500;
      color: red;
      text-align: right;
    }
  }

  .noshop-items {
    width: 100%;
    height: 18vh;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .sx {
      font-size: 10px;
      width: 40px;
      height: 20px;
      border-radius: 10px;
      line-height: 20px;
      text-align: center;
      background: #A3A3A3;
      color: #ffffff;
    }

    > img {
      width: 30%;
      height: 80%;
    }

    .lose-title {
      width: 50%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 10px;

      div {
        text-align: left;
      }

      .div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #999999;
      }

      .lt-div {
        font-weight: bold;
      }
    }
  }
}
</style>
