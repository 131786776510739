<template>
  <div class="tab_all">
    <div class="tab_box">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="tab_list"
        @click.once="btnGo(item, index)"
      >
        <span v-if="index === 2 && cartCount !== 0" class="tab-badge">
          {{ cartCount }}
        </span>
        <div class="tab_list_s">
          <div class="tab_list_img" @click="inNub = index">
            <img
              :src="inNub == index ? item.active : item.inactive"
              :class="index == 0 && inNub == index ? 'bf' : ''"
            >
          </div>
          <h4 :class="inNub == index ? 'bf' : ''" @click="inNub = index">
            {{ inNub == index ? item.title : item.name }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inNub: window.localStorage.getItem('inNub') || 0, //  qu
      list: [
        {
          title: '',
          name: '悦商城',
          inactive: require('@/assets/images/tabbar/home0.png'),
          active: require('@/assets/images/tabbar/home1.png'),
          path: '/'
        },
        {
          title: '必买爆款',
          name: '必买爆款',
          inactive: require('@/assets/images/tabbar/buy0.png'),
          active: require('@/assets/images/tabbar/buy1.png'),
          path: '/MustBuy'
        },
        {
          title: '购物车',
          name: '购物车',
          inactive:
            'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-07-17/14/yuelvhuiJc9va5fUns1594967304.png',
          active:
            'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-07-17/14/yuelvhuix9xSL7mGdF1594967336.png',

          path: '/shopCar'
        },
        {
          title: '我的',
          name: '我的',
          inactive: require('@/assets/images/tabbar/my0.png'),
          active: require('@/assets/images/tabbar/my1.png'),
          path: '/user'
        }
      ]
    }
  },
  computed: {
    cartCount() {
      return this.$store.state.cartCount
    }
  },

  mounted() {
    this.$store.dispatch('getCartCount')
    this.watchRoute()
  },

  methods: {
    btnGo: function(item, index) {
      window.localStorage.setItem('inNub', this.inNub)
      this.$router.push({ path: item.path })
    },
    // 监听返回上一页路径
    watchRoute() {
      this.list.forEach((e, index) => {
        if (e.path === this.$route.path) {
          this.inNub = index
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.tab_all {
  width: 100%;
  height: 48px;
  position: fixed;
  background: #fff;
  bottom: 0;

  .tab_box {
    width: 100%;
    display: flex;

    .tab_list {
      flex: 1;
      height: 48px;
      position: relative;

      .tab-badge {
        background: red;
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 10px;
        transform: scale(0.9);
        color: #fff;
        position: absolute;
        top: 0px;
        right: 21px;
      }

      .tab_list_s {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .tab_list_img {
          img {
            width: 16px;
            margin: 0 auto;
          }

          img.bf {
            width: 30px;
          }
        }

        h4 {
          width: max-content;
          text-align: center;
          font-size: 12px;
          color: #999;
        }

        h4.bf {
          color: #333;
        }
      }
    }
  }
}
</style>
